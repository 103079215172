import { http } from "@/http/axios.js";
import { dateFormat } from "@/utils/index.js";
import { exportData } from "@/utils/export.js";

// 列表
export function getListAPI(params) {
  return http({
    url: "/admin/order/bill/page",
    method: "get",
    params,
  });
}

export const exportTodayExpiredBill = (data) => {
  exportData(
    "/api/admin/order/bill/today/export",
    data,
    `${dateFormat()}到期账单.xlsx`,
  );
};

// 全部导出
export function DownloadAPI(data) {
  exportData(
    "/api/admin/order/bill/export",
    data,
    `账单明细${dateFormat()}.xlsx`,
  );
}
