import axios from "axios";
import { getToken } from "@/utils/auth";
import { showScreenLoading, hideScreenLoading } from "@/http/loading.js";

export const downloadAsFile = (data, fileName) => {
  let a = document.createElement("a");
  a.style.display = "none";
  a.href = window.URL.createObjectURL(data);
  a.setAttribute("download", decodeURIComponent(fileName));
  document.body.appendChild(a);
  a.click(); //执行下载
  window.URL.revokeObjectURL(a.href); //释放url
  document.body.removeChild(a); //释放标签
};

export const exportData = async (url, data, fileName) => {
  showScreenLoading();

  try {
    const res = await axios({
      method: "post",
      url,
      responseType: "blob",
      data,
      headers: {
        Authorization: getToken() || "",
      },
    });

    downloadAsFile(new Blob([res.data]), fileName);
  } catch (error) {
    console.error(error);
  } finally {
    hideScreenLoading();
  }
};
