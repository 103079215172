<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <el-form
      inline
      class="search-header"
      :model="searchData"
      label-width="110px"
    >
      <el-form-item label="关键字" class="kw">
        <el-input
          placeholder="请输入"
          v-model="searchData.keywordValue"
          class="input-with-select"
          size="small"
        >
          <el-select
            v-model="searchData.keywordName"
            slot="prepend"
            placeholder="请选择"
            size="small"
          >
            <el-option
              v-for="item in searchTypeBill"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-input>
      </el-form-item>
      <el-form-item label="订单类型">
        <el-select
          style="width: 180px"
          v-model="searchData.orderType"
          placeholder="请选择"
          size="small"
          clearable
        >
          <el-option
            v-for="item in dict_order_type"
            :key="item.value"
            :label="item.name"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="套餐类型">
        <SelectDict
          ref="combo"
          v-model="searchData.goodsCombo"
          width="180px"
          dictCode="goods:combo"
        ></SelectDict>
      </el-form-item>
      <el-form-item label="是否免押">
        <el-select
          style="width: 180px"
          v-model="searchData.enableAlipayFundAuth"
          placeholder="请选择"
          size="small"
          clearable
        >
          <el-option label="否" :value="false"> </el-option>
          <el-option label="是" :value="true"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="下单时间">
        <el-date-picker
          style="width: 300px"
          size="small"
          v-model="searchData.timeSlotOrder"
          type="daterange"
          align="right"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="期数">
        <el-input-number
          placeholder="请输入"
          size="small"
          style="width: 150px"
          v-model="searchData.leftBillNum"
          :step="1"
          :min="null"
          step-strictly
          controls-position="right"
        ></el-input-number>
        <span> 至 </span>
        <el-input-number
          placeholder="请输入"
          size="small"
          style="width: 150px"
          v-model="searchData.rightBillNum"
          :step="1"
          :min="null"
          step-strictly
          controls-position="right"
        ></el-input-number>
      </el-form-item>
      <el-form-item label="账单日">
        <el-date-picker
          style="width: 300px"
          size="small"
          v-model="searchData.timeSlotBill"
          type="daterange"
          align="right"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="订单状态">
        <el-select
          style="width: 180px"
          v-model="searchData.orderStatusList"
          placeholder="请选择"
          size="small"
          clearable
          multiple
        >
          <el-option
            v-for="item in orderStatus2"
            :key="item.value"
            :label="item.name"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="租金支付类型">
        <SelectDict
          width="180px"
          v-model="searchData.principalPayMode"
          dictCode="pay:mode"
        ></SelectDict>
      </el-form-item>
      <el-form-item label="是否预付">
        <el-select
          style="width: 180px"
          v-model="searchData.isPrepay"
          placeholder="请选择"
          size="small"
          clearable
        >
          <el-option label="否" :value="false"> </el-option>
          <el-option label="是" :value="true"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="支付完成时间">
        <el-date-picker
          style="width: 300px"
          size="small"
          v-model="searchData.timeSlotFinal"
          type="daterange"
          align="right"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="账单逾期天数">
        <el-input-number
          placeholder="请输入"
          size="small"
          style="width: 150px"
          v-model="searchData.leftOverdueDays"
          :step="1"
          :min="null"
          step-strictly
          controls-position="right"
        ></el-input-number>
        <span> 至 </span>
        <el-input-number
          placeholder="请输入"
          size="small"
          style="width: 150px"
          v-model="searchData.rightOverdueDays"
          :step="1"
          :min="null"
          step-strictly
          controls-position="right"
        ></el-input-number>
      </el-form-item>
      <el-form-item label="客户逾期天数">
        <el-input-number
          placeholder="请输入"
          size="small"
          style="width: 150px"
          v-model="searchData.leftMemberOverdueDays"
          :step="1"
          :min="null"
          step-strictly
          controls-position="right"
        ></el-input-number>
        <span> 至 </span>
        <el-input-number
          placeholder="请输入"
          size="small"
          style="width: 150px"
          v-model="searchData.rightMemberOverdueDays"
          :step="1"
          :min="null"
          step-strictly
          controls-position="right"
        ></el-input-number>
      </el-form-item>
      <el-form-item label="是否延期">
        <el-select
          style="width: 180px"
          v-model="searchData.isDateModify"
          placeholder="请选择"
          size="small"
          clearable
        >
          <el-option label="否" :value="false"> </el-option>
          <el-option label="是" :value="true"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="锁机状态">
        <el-select
          style="width: 180px"
          v-model="searchData.lockStatus"
          placeholder="请选择"
          size="small"
          clearable
        >
          <el-option label="未锁机" :value="0"> </el-option>
          <el-option label="待锁机" :value="1"> </el-option>
          <el-option label="已锁机" :value="2"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="待锁机日期">
        <el-date-picker
          style="width: 300px"
          v-model="searchData.timeSlotLock"
          size="small"
          type="daterange"
          value-format="yyyy-MM-dd"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="门店归属">
        <el-input
          placeholder="请输入"
          size="small"
          style="width: 180px"
          v-model="searchData.storeBusinessUserName"
        ></el-input>
      </el-form-item>
      <el-form-item label="是否首期非首付">
        <el-select
          style="width: 180px"
          v-model="searchData.isFupBill"
          placeholder="请选择"
          size="small"
          clearable
        >
          <el-option label="否" :value="false"> </el-option>
          <el-option label="是" :value="true"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="">
        <el-button
          @click="getList"
          type="primary"
          icon="el-icon-search"
          size="small"
          >查询</el-button
        >
        <el-button
          @click="reset"
          size="small"
          type="info"
          icon="el-icon-refresh"
          >重置</el-button
        >
      </el-form-item>
    </el-form>

    <div class="header-container" v-if="false">
      <div class="left">
        <el-form
          inline
          class="search-header"
          :model="searchData"
          label-width="80px"
        >
          <el-form-item label="关键字" class="keywords">
            <el-input
              placeholder="请输入"
              v-model="searchData.keywordValue"
              class="input-with-select"
              size="small"
            >
              <el-select
                v-model="searchData.keywordName"
                slot="prepend"
                placeholder="请选择"
                size="small"
              >
                <el-option
                  v-for="item in searchTypeBill"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-input>
          </el-form-item>
          <el-form-item label="订单类型">
            <el-select
              style="width: 350px"
              v-model="searchData.orderType"
              placeholder="请选择"
              size="small"
              clearable
            >
              <el-option
                v-for="item in dict_order_type"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="租金支付类型" class="pay-type">
            <SelectDict
              v-model="searchData.principalPayMode"
              dictCode="pay:mode"
            ></SelectDict>
          </el-form-item>
          <el-form-item label="账单日">
            <el-date-picker
              style="width: 350px"
              size="small"
              v-model="searchData.timeSlotBill"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="支付完成时间">
            <el-date-picker
              style="width: 350px"
              size="small"
              v-model="searchData.timeSlotFinal"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="账单逾期天数" v-show="isShow">
            <el-input-number
              placeholder="请输入"
              size="small"
              style="width: 165px"
              v-model="searchData.leftOverdueDays"
              :step="1"
              :min="null"
              step-strictly
              controls-position="right"
            ></el-input-number>
            <span> 至 </span>
            <el-input-number
              placeholder="请输入"
              size="small"
              style="width: 165px"
              v-model="searchData.rightOverdueDays"
              :step="1"
              :min="null"
              step-strictly
              controls-position="right"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="下单时间" v-show="isShow">
            <el-date-picker
              style="width: 350px"
              size="small"
              v-model="searchData.timeSlotOrder"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="订单状态" v-show="isShow">
            <el-select
              style="width: 350px"
              v-model="searchData.orderStatusList"
              placeholder="请选择"
              size="small"
              clearable
              multiple
            >
              <el-option
                v-for="item in orderStatus2"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="期数" v-show="isShow">
            <el-input-number
              placeholder="请输入"
              size="small"
              style="width: 165px"
              v-model="searchData.leftBillNum"
              :step="1"
              :min="null"
              step-strictly
              controls-position="right"
            ></el-input-number>
            <span> 至 </span>
            <el-input-number
              placeholder="请输入"
              size="small"
              style="width: 165px"
              v-model="searchData.rightBillNum"
              :step="1"
              :min="null"
              step-strictly
              controls-position="right"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="客户逾期天数" v-show="isShow">
            <el-input-number
              placeholder="请输入"
              size="small"
              style="width: 165px"
              v-model="searchData.leftMemberOverdueDays"
              :step="1"
              :min="null"
              step-strictly
              controls-position="right"
            ></el-input-number>
            <span> 至 </span>
            <el-input-number
              placeholder="请输入"
              size="small"
              style="width: 165px"
              v-model="searchData.rightMemberOverdueDays"
              :step="1"
              :min="null"
              step-strictly
              controls-position="right"
            ></el-input-number>
          </el-form-item>
        </el-form>
      </div>
      <div class="right">
        <span class="open" @click="isShow = !isShow">{{
          isShow ? "收起" : "展开"
        }}</span>
        <i class="updown" :class="['el-icon-arrow-up', isShow ? '' : 'go']"></i>
        <el-button
          style="margin-left: 10px"
          @click="getList"
          type="primary"
          icon="el-icon-search"
          size="small"
          >查询</el-button
        >
        <el-button
          @click="reset"
          size="small"
          type="info"
          icon="el-icon-refresh"
          >重置</el-button
        >
      </div>
    </div>
    <!-- <el-divider></el-divider> -->
    <!-- <el-tabs v-model="searchData.activeStatus">
      <el-tab-pane v-for="tab in billStatus" :key="tab.value" :label="tab.name" :name="tab.value"></el-tab-pane>
    </el-tabs> -->
    <div class="header-tab">
      <div class="left-tab">
        <span
          @click="searchData.activeStatus = tab.value"
          v-for="tab in billStatus"
          :key="tab.value"
        >
          <el-badge
            :class="[searchData.activeStatus == tab.value ? 'select-tab' : '']"
          >
            {{ tab.name }}
          </el-badge>
        </span>
      </div>

      <div>
        <el-button
          v-checkbtn="isShowBtn(AUTH_BTN.order_bill_expired_today_export)"
          type="primary"
          size="small"
          icon="el-icon-download"
          @click="downloadExpiredTodayExcel"
          >今日到期导出</el-button
        >

        <el-button
          v-checkbtn="isShowBtn(AUTH_BTN.order_bill_list_export)"
          type="primary"
          size="small"
          icon="el-icon-download"
          @click="downloadExcel"
          >导出</el-button
        >
      </div>
    </div>
    <div class="header-count">
      <!-- <div class="selected">
        <i class="el-icon-warning"></i>已选择：<span>{{ tableSelected.length }}</span>
      </div> -->
      <!-- <div></div>
      <el-button type="primary" size="small" icon="el-icon-download" @click="downloadExcel">导出</el-button> -->
    </div>
    <tp-table
      @handleSelectionChange="handleSelectionChange"
      :isCheckBox="false"
      :tableData="list"
      :columns="columns"
      :totalNum="total"
      :current-page.sync="currentPage"
      :pageSize.sync="pageSize"
    />
    <div style="height: 50px"></div>
  </div>
</template>

<script>
import { authBtnMixin } from "@/mixins/authBtnMixin";
import { getListAPI, DownloadAPI, exportTodayExpiredBill } from "./api";
import {
  searchTypeBill,
  billStatus,
  orderStatus2,
  dict_order_type,
} from "@/enum/dict/index.js";
import SelectDict from "@/views/components/select-dict.vue";
const columns = [
  {
    label: "用户信息",
    prop: "member",
    minWidth: "180",
    customRender(h, row) {
      return (
        <div>
          <div>姓名：{row["member"]?.["memberName"]}</div>
          <div>手机号：{row["member"]?.["mobile"]}</div>
        </div>
      );
    },
  },
  {
    label: "账单状态",
    prop: "order",
    minWidth: "100",
    customRender(h, row) {
      return <p>{row["statusName"]}</p>;
    },
  },
  {
    label: "账单状态(客户端)",
    prop: "status2Name",
    minWidth: "150",
  },
  {
    label: "订单状态",
    prop: "order",
    minWidth: "100",
    customRender(h, row) {
      return <p>{row["order"]?.["statusName"]}</p>;
    },
  },
  {
    label: "订单状态(客户端)",
    prop: "order",
    minWidth: "150",
    customRender(h, row) {
      return <p>{row["order"]?.["status2Name"]}</p>;
    },
  },
  {
    label: "售后状态",
    prop: "order",
    minWidth: "100",
    customRender(h, row) {
      return <p>{row["order"]?.["afterSaleStatusName"]}</p>;
    },
  },
  {
    label: "设备监管信息",
    prop: "order",
    minWidth: "180",
    customRender(h, row) {
      return (
        <p>
          <div v-show={row["order"]}>
            锁机状态：
            <el-tag
              v-show={row["order"]["lockStatus"] == 0}
              type="success"
              size="mini"
            >
              未锁机
            </el-tag>
            <el-tag
              v-show={row["order"]["lockStatus"] == 1}
              type="warning"
              size="mini"
            >
              待锁机
            </el-tag>
            <el-tag
              v-show={row["order"]["lockStatus"] == 2}
              type="danger"
              size="mini"
            >
              已锁机
            </el-tag>
          </div>
          <div v-show={row["order"]}>
            待锁机日期：
            {row["order"]["lockDate"] || "无"}
          </div>
          <div v-show={row["order"]}>
            设备状态： {row["order"]?.["deviceStatusName"]}
          </div>
        </p>
      );
    },
  },
  {
    label: "账单逾期天数",
    prop: "overdueDays",
    minWidth: "120",
    customRender(h, row) {
      return <p>{row["overdueDays"]}</p>;
    },
  },
  {
    label: "客户在逾天数",
    prop: "overdueDays",
    minWidth: "120",
    customRender(h, row) {
      return <p>{row["member"]?.["overdueDays"]}</p>;
    },
  },
  {
    label: "下单时间",
    prop: "order",
    minWidth: "180",
    customRender(h, row) {
      return <p>{row["order"]?.["createTime"]}</p>;
    },
  },
  {
    label: "期数",
    prop: "billNum",
    minWidth: "120",
    customRender(h, row) {
      return (
        <div>
          <p>
            {row["billNum"]}
            <el-tag
              style="margin-left:5px"
              v-show={row["isPrepay"]}
              type="info"
              size="mini"
            >
              预付
            </el-tag>
            <el-tag
              style="margin-left:5px"
              v-show={row["isFupBill"]}
              type="info"
              size="mini"
            >
              首期非首付
            </el-tag>
          </p>
        </div>
      );
    },
  },
  {
    label: "原账单日",
    prop: "originBillDate",
    minWidth: "120",
    customRender(h, row) {
      return <p>{row["originBillDate"]}</p>;
    },
  },
  {
    label: "上次账单日",
    prop: "beforeBillDate",
    minWidth: "120",
    customRender(h, row) {
      return <p>{row["beforeBillDate"]}</p>;
    },
  },
  {
    label: "当前账单日",
    prop: "billDate",
    minWidth: "120",
    customRender(h, row) {
      return <p>{row["billDate"]}</p>;
    },
  },
  {
    label: "下期账单日",
    prop: "nextBillDate",
    minWidth: "120",
    customRender(h, row) {
      return <p>{row["nextBillDate"]}</p>;
    },
  },
  {
    label: "延期天数",
    prop: "delayDayNum",
    minWidth: "120",
    customRender(h, row) {
      return <p>{row["delayDayNum"]}</p>;
    },
  },
  {
    label: "租金",
    prop: "principalAmount",
    minWidth: "100",
    customRender(h, row) {
      return <p>{row["principalAmount"]}</p>;
    },
  },
  {
    label: "租金支付类型",
    prop: "principalPayModeName",
    minWidth: "120",
    customRender(h, row) {
      return <p>{row["principalPayModeName"]}</p>;
    },
  },
  {
    label: "滞纳金",
    prop: "overdueAmount",
    minWidth: "100",
    customRender(h, row) {
      return <p>{row["overdueAmount"]}</p>;
    },
  },
  {
    label: "减免金额",
    prop: "offAmount",
    minWidth: "100",
    customRender(h, row) {
      return <p>{row["offAmount"]}</p>;
    },
  },
  {
    label: "待支付金额",
    prop: "leftPayAmount",
    minWidth: "100",
    customRender(h, row) {
      return <p>{row["leftPayAmount"]}</p>;
    },
  },
  {
    label: "支付完成时间",
    prop: "finalPayTime",
    minWidth: "180",
    customRender(h, row) {
      return <p>{row["finalPayTime"]}</p>;
    },
  },
  // {
  //   label: '售后',
  //   prop: 'afterSaleUser',
  //   minWidth: '100',
  //   customRender(h, row) {
  //     return <p>{row['afterSaleUser']?.['userName']}</p>
  //   }
  // },
  {
    label: "审核",
    prop: "reviewUser",
    minWidth: "100",
    customRender(h, row) {
      return <p>{row["reviewUser"]?.["userName"]}</p>;
    },
  },
  {
    label: "门店信息",
    prop: "Store",
    minWidth: "200",
    customRender(h, row) {
      return (
        <div>
          <div>门店名称：{row["storeName"]}</div>
          <div>门店归属：{row["storeBusinessUserName"]}</div>
        </div>
      );
    },
  },
  {
    label: "订单信息",
    prop: "order",
    minWidth: "280",
    fixed: "right",
    customRender(h, row) {
      return (
        <div>
          <div>订单单号：{row["order"]?.["orderNo"]}</div>
          <div>
            订单类型：
            <el-tag
              v-show={row["order"]?.["orderType"] == 1}
              type="primary"
              size="mini"
            >
              普通订单
            </el-tag>
            <el-tag
              v-show={row["order"]?.["orderType"] == 2}
              type="warning"
              size="mini"
            >
              门店订单
            </el-tag>
            <el-tag
              v-show={row["order"]?.["orderType"] == 3}
              type="success"
              size="mini"
            >
              卡券订单
            </el-tag>
            <el-tag
              v-show={row["order"]?.["orderType"] == 4}
              color="#ffcccc54"
              style="color:#ff7744;border-color:#ffcccc54"
              size="mini"
            >
              电车订单
            </el-tag>
            <el-tag
              v-show={row["order"]?.["enableAlipayFundAuth"] == true}
              type="danger"
              size="mini"
              style="margin-left: 5px"
            >
              免押
            </el-tag>
          </div>
          <div>
            套餐类型：
            <el-tag type="primary" size="mini">
              {this.filterCombo(row["order"]?.["goodsCombo"])}
            </el-tag>
          </div>
        </div>
      );
    },
  },
];
export default {
  name: "List",
  mixins: [authBtnMixin],
  components: { SelectDict },
  data() {
    return {
      isShow: false, //控制箭头
      columns,
      currentPage: 1,
      pageSize: 10,
      total: 0,
      list: [],
      searchData: {
        activeStatus: "0",
        keywordName: "",
        keywordValue: "",
        leftOverdueDays: "",
        principalPayMode: "",
        rightOverdueDays: "",
        timeSlotFinal: [],
        leftFinalPayDate: "",
        rightFinalPayDate: "",
        timeSlotBill: [],
        leftBillDate: "",
        rightBillDate: "",
        timeSlotOrder: [],
        leftOrderDate: "",
        rightOrderDate: "",
        orderStatusList: [],
        orderType: "",
        rightBillNum: "",
        leftBillNum: "",
        leftMemberOverdueDays: "",
        rightMemberOverdueDays: "",
        goodsCombo: "",
        isDateModify: null,
        enableAlipayFundAuth: null,
        isPrepay: null,
        lockStatus: "",
        timeSlotLock: [],
        storeBusinessUserName: "",
        isFupBill: null,
      },
      // 字典
      searchTypeBill,
      billStatus,
      orderStatus2,
      dict_order_type,
      // 已勾选
      tableSelected: [],
    };
  },
  provide() {
    return {
      context: this,
    };
  },
  mounted() {
    // this.getList()
    window["getBpageList"] = () => {
      this.getList();
    };
  },
  activated() {
    this.getList();
  },
  watch: {
    currentPage() {
      this.getList();
    },
    pageSize() {
      this.getList();
    },
    "searchData.activeStatus": {
      // immediate: true,
      handler(newV, oldV) {
        this.getList();
      },
    },
  },
  methods: {
    downloadExpiredTodayExcel() {
      exportTodayExpiredBill();
    },

    async getList() {
      const {
        timeSlotFinal,
        timeSlotBill,
        timeSlotOrder,
        keywordName,
        keywordValue,
        activeStatus,
        leftOverdueDays,
        rightOverdueDays,
        principalPayMode,
        orderStatusList,
        orderType,
        leftBillNum,
        rightBillNum,
        rightMemberOverdueDays,
        leftMemberOverdueDays,
        goodsCombo,
        isDateModify,
        enableAlipayFundAuth,
        isPrepay,
        lockStatus,
        timeSlotLock,
        storeBusinessUserName,
        isFupBill,
      } = this.searchData;
      let params = {
        page: this.currentPage,
        pageSize: this.pageSize,
        leftOverdueDays,
        rightOverdueDays,
        principalPayMode,
        orderStatusList: orderStatusList.join(),
        orderType,
        leftBillNum,
        rightBillNum,
        rightMemberOverdueDays,
        leftMemberOverdueDays,
        goodsCombo,
        isDateModify,
        enableAlipayFundAuth,
        isPrepay,
        lockStatus,
        storeBusinessUserName,
        isFupBill,
      };
      if (activeStatus != 0) params.status = activeStatus - 0;
      if (timeSlotFinal && timeSlotFinal.length > 0) {
        params.leftFinalPayDate = timeSlotFinal[0];
        params.rightFinalPayDate = timeSlotFinal[1];
      }
      if (timeSlotBill && timeSlotBill.length > 0) {
        params.leftBillDate = timeSlotBill[0];
        params.rightBillDate = timeSlotBill[1];
      }
      if (timeSlotOrder && timeSlotOrder.length > 0) {
        params.leftOrderDate = timeSlotOrder[0];
        params.rightOrderDate = timeSlotOrder[1];
      }
      if (timeSlotLock && timeSlotLock.length > 0) {
        params.leftLockDate = timeSlotLock[0];
        params.rightLockDate = timeSlotLock[1];
      }
      if (keywordName) params[keywordName] = keywordValue;
      const res = await getListAPI(params);
      this.list = res.list || [];
      this.total = res.total || 0;
    },
    handleSelectionChange(val) {
      this.tableSelected = val;
    },
    reset() {
      Object.assign(this._data.searchData, this.$options.data().searchData);
      if (this.searchData.activeStatus != "0")
        return (this.searchData.activeStatus = "0");
      this.getList();
    },

    downloadExcel() {
      const {
        timeSlotFinal,
        timeSlotBill,
        timeSlotOrder,
        keywordName,
        keywordValue,
        activeStatus,
        leftOverdueDays,
        rightOverdueDays,
        principalPayMode,
        orderStatusList,
        orderType,
        leftBillNum,
        rightBillNum,
        rightMemberOverdueDays,
        leftMemberOverdueDays,
        goodsCombo,
        isDateModify,
        enableAlipayFundAuth,
        isPrepay,
        lockStatus,
        timeSlotLock,
        storeBusinessUserName,
        isFupBill,
      } = this.searchData;
      let params = {
        page: this.currentPage,
        pageSize: this.pageSize,
        leftOverdueDays,
        rightOverdueDays,
        principalPayMode,
        orderStatusList,
        orderType,
        leftBillNum,
        rightBillNum,
        rightMemberOverdueDays,
        leftMemberOverdueDays,
        goodsCombo,
        isDateModify,
        enableAlipayFundAuth,
        isPrepay,
        lockStatus,
        storeBusinessUserName,
        isFupBill,
      };
      if (activeStatus != 0) params.status = activeStatus - 0;
      if (timeSlotFinal && timeSlotFinal.length > 0) {
        params.leftFinalPayDate = timeSlotFinal[0];
        params.rightFinalPayDate = timeSlotFinal[1];
      }
      if (timeSlotBill && timeSlotBill.length > 0) {
        params.leftBillDate = timeSlotBill[0];
        params.rightBillDate = timeSlotBill[1];
      }
      if (timeSlotOrder && timeSlotOrder.length > 0) {
        params.leftOrderDate = timeSlotOrder[0];
        params.rightOrderDate = timeSlotOrder[1];
      }
      if (timeSlotLock && timeSlotLock.length > 0) {
        params.leftLockDate = timeSlotLock[0];
        params.rightLockDate = timeSlotLock[1];
      }
      if (keywordName) params[keywordName] = keywordValue;
      DownloadAPI(params);
    },
    // 套餐类型
    filterCombo(str) {
      let name = "";
      const list = this.$refs.combo.list;
      const res = list.find((item) => item.value == str);
      if (res) {
        name = res.name;
      }
      return name;
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
  width: 100%;

  .el-form {
    .el-form-item {
      margin-right: 15px;
      margin-bottom: 10px;
      // .el-input,
      // .el-select {
      //   width: 360px;
      // }
      .el-date-editor {
        .el-range-separator {
          padding: 0;
        }
        .el-range-input {
          width: 35%;
        }
      }
    }
    .kw {
      .el-input,
      .el-select {
        width: 320px;
      }
      .el-select .el-input {
        width: 100px;
      }
      .el-select {
        width: 110px;
      }
      .input-with-select {
        vertical-align: middle;
      }
      .input-with-select .el-input-group__prepend {
        background-color: #fff;
      }
    }
  }

  .el-divider--horizontal {
    margin: 0 0 10px;
  }
  .header-count {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    .selected {
      margin-right: 20px;
      padding: 0 10px;
      flex: 1;
      background-color: #e6f7ff;
      border: 1px solid #a3dcff;
      font-size: 12px;
      align-items: center;
      display: flex;
      i {
        color: #409eff;
        margin-right: 10px;
        font-size: 14px;
      }
      span {
        color: #409eff;
        font-size: 14px;
        margin-right: 10px;
      }
    }
    // .selected {
    //   // margin-top: 10px;
    //   padding: 0 10px;
    //   background-color: #e6f7ff;
    //   border: 1px solid #a3dcff;
    //   font-size: 12px;
    //   height: 30px;
    //   line-height: 30px;
    //   i {
    //     color: #409eff;
    //     margin-right: 10px;
    //     font-size: 14px;
    //   }
    //   span {
    //     color: #409eff;
    //     font-size: 14px;
    //     margin-right: 10px;
    //   }
    // }
  }
  .caozuo {
    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  .price {
    .item {
      min-width: 150px;
      display: flex;
      span {
        min-width: 70px;
        &:first-child {
          // min-width: 70px;
          flex: 1;
          text-align: right;
          // margin-right: 10px;
        }
        &:last-child {
          text-align: left;
        }
      }
    }
  }
  .header-tab {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid #dcdfe6;
    height: 40px;
    line-height: 40px;
    margin-bottom: 20px;
    margin-top: 20px;
    .left-tab {
      & > span {
        cursor: pointer;
        margin-right: 30px;
      }
      .select-tab {
        color: #409eff;
        border-bottom: 2px solid #409eff;
      }

      .el-badge {
        .el-badge__content.is-fixed {
          top: 7px;
          right: 5px;
        }
        .el-badge__content {
          height: 20px;
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.wrapper /deep/ {
  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .left {
      flex: 1;
      .el-form-item {
        margin-bottom: 0;
        .el-date-editor {
          .el-range-separator {
            padding: 0;
          }
          // .el-range-input {
          //   // width: 30%;
          // }
        }
      }
      .pay-type {
        .el-select {
          width: 350px !important;
        }
      }
    }
    .right {
      margin-left: 20px;
      .open {
        color: #409eff;
        font-size: 16px;
        margin: 0 10px;
        cursor: pointer;
      }
      .updown {
        color: #409eff;
        font-size: 16px;
        transition: all 0.5s;
      }
      .go {
        transform: rotate(-180deg);
      }
    }
  }
  .search-header /deep/ {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .arrow {
      // text-align: right;
      .open {
        color: #409eff;
        font-size: 16px;
        margin: 0 10px;
        cursor: pointer;
      }
      .updown {
        color: #409eff;
        font-size: 16px;
        transition: all 0.5s;
      }
      .go {
        transform: rotate(-180deg);
      }
    }
    .el-form-item {
      width: 40%;
      min-width: 400px;
    }
  }
}
</style>
